import * as srp from '@getinsomnia/srp-js';
import * as Sentry from '@sentry/remix';
import type { Invite, InviteInstruction, InviteKey } from '~/services/organizations.server';
import type { EnterpriseTransferBegin, EnterpriseUpdateRoleBegin } from '~/services/enterprise.server';
import type { MemberProjectKey, Whoami } from '~/generated-types';

export function buildInviteByInstruction(
  instruction: InviteInstruction,
  rawProjectKeys: DecryptedProjectKey[],
): Invite {
  let inviteKeys: InviteKey[] = [];
  if (rawProjectKeys?.length) {
    const inviteePublicKey = JSON.parse(instruction.inviteePublicKey);
    inviteKeys = rawProjectKeys.map((key) => {
      const reEncryptedSymmetricKey = srp.encryptRSAWithJWK(inviteePublicKey, key.symmetricKey);
      return {
        projectId: key.projectId,
        encSymmetricKey: reEncryptedSymmetricKey,
        autoLinked: instruction.inviteeAutoLinked,
      };
    });
  }
  return {
    inviteeId: instruction.inviteeId,
    inviteeEmail: instruction.inviteeEmail,
    inviteKeys,
  };
}

export function buildMemberProjectKey(
  accountId: string,
  projectId: string,
  publicKey: string,
  rawProjectKey?: string,
): MemberProjectKey | null {
  if (!rawProjectKey) return null;
  const acctPublicKey = JSON.parse(publicKey);
  const encSymmetricKey = srp.encryptRSAWithJWK(acctPublicKey, rawProjectKey);
  return {
    projectId,
    accountId,
    encSymmetricKey,
  };
}

export function buildTransfer(transfer: EnterpriseTransferBegin, inviterPrivateKey: JsonWebKey): Invite {
  let inviteKeys: InviteKey[] = [];
  if (transfer.inviteKeys?.length) {
    inviteKeys = transfer.inviteKeys.map((key) =>
      reEncryptInviteKey(key, inviterPrivateKey, transfer.inviteePublicKey),
    );
  }
  return {
    inviteeId: transfer.inviteeId,
    inviteeEmail: transfer.inviteeEmail,
    inviteKeys,
  };
}

export function buildUpdateRoleData(updateBeginData: EnterpriseUpdateRoleBegin, inviterPrivateKey: JsonWebKey): Invite {
  let inviteKeys: InviteKey[] = [];
  if (updateBeginData.inviteKeys?.length) {
    inviteKeys = updateBeginData.inviteKeys.map((key) =>
      reEncryptInviteKey(key, inviterPrivateKey, updateBeginData.inviteePublicKey),
    );
  }
  return {
    inviteeId: updateBeginData.inviteeId,
    inviteeEmail: updateBeginData.inviteeEmail,
    inviteKeys,
  };
}

export type PrivateKeyParams = Pick<Whoami, 'email' | 'saltEnc' | 'encPrivateKey' | 'encSymmetricKey' | 'encDriverKey'>;

export async function getPrivateKey(params: PrivateKeyParams, rawPassphrase: string | null): Promise<JsonWebKey> {
  let privateKey: string | null = null;

  if (rawPassphrase !== null) {
    const secret = await deriveSymmetricKey(params, rawPassphrase);
    const { encPrivateKey, encSymmetricKey } = params;

    let symmetricKey: string;
    try {
      symmetricKey = srp.decryptAES(secret, JSON.parse(encSymmetricKey));
    } catch (err) {
      Sentry.captureException(err);
      console.log('Failed to decrypt wrapped private key', err);
      throw new InvalidPassphraseError();
    }

    privateKey = srp.decryptAES(JSON.parse(symmetricKey), JSON.parse(encPrivateKey));
  } else {
    throw new NeedPassphraseError();
  }

  return JSON.parse(privateKey) as JsonWebKey;
}

export function reEncryptProjectKey(publicKey: JsonWebKey, symmetricKey: string) {
  return srp.encryptRSAWithJWK(publicKey, symmetricKey);
}

function reEncryptInviteKey(
  key: InviteKey,
  inviterPrivateKey: JsonWebKey,
  serializedinviteePublicKey: string,
): InviteKey {
  try {
    const inviteePublicKey = JSON.parse(serializedinviteePublicKey);
    const symmetricKey = srp.decryptRSAWithJWK(inviterPrivateKey, key.encSymmetricKey);
    const encProjectSymmetricKey = srp.encryptRSAWithJWK(inviteePublicKey, symmetricKey);
    return {
      encSymmetricKey: encProjectSymmetricKey,
      projectId: key.projectId,
      autoLinked: key.autoLinked,
    };
  } catch (error) {
    throw error;
  }
}

export type EncryptedProjectKey = {
  projectId: string;
  encKey: string;
};
export type DecryptedProjectKey = {
  projectId: string;
  symmetricKey: string;
};
export async function decryptProjectKeys(
  decryptionKey: JsonWebKey,
  projectKeys: EncryptedProjectKey[],
): Promise<DecryptedProjectKey[]> {
  try {
    const promises = projectKeys.map((key) => {
      const symmetricKey = srp.decryptRSAWithJWK(decryptionKey, key.encKey);
      return {
        projectId: key.projectId,
        symmetricKey,
      };
    });

    const decrpyted = await Promise.all(promises);
    return decrpyted;
  } catch (error) {
    throw error;
  }
}

export function decryptEncryptedManagedKey(privateKey: JsonWebKey, encryptedKey: string) {
  const managedPassphrase = srp.decryptRSAWithJWK(privateKey, encryptedKey);
  return managedPassphrase;
}

async function deriveSymmetricKey(params: PrivateKeyParams, rawPassphrase: string): Promise<string> {
  const passPhrase = _sanitizePassphrase(rawPassphrase);
  const { encDriverKey, saltEnc } = params;
  return await srp.deriveKey(passPhrase, encDriverKey, saltEnc);
}

function _sanitizePassphrase(passphrase: string) {
  return passphrase.trim().normalize('NFKD');
}

class NeedPassphraseError extends Error {
  constructor() {
    super('Passphrase required');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, NeedPassphraseError.prototype);
  }
}

class InvalidPassphraseError extends Error {
  constructor() {
    super('Invalid passphrase');

    // This trick is necessary to extend a native type from a transpiled ES6 class.
    Object.setPrototypeOf(this, InvalidPassphraseError.prototype);
  }
}
